// eslint globals
/* global Swiper */
document.addEventListener('DOMContentLoaded', function () {


  // drop open and hide when click outside
  const links = document.querySelectorAll('.js-dropdown-link');
  const innerElements = document.querySelectorAll('.js-dropdown');

  function handleClick(e) {
    const targetElement = document.getElementById(this.hash.substr(1));
    const isAlreadyActive = this.classList.contains('active');
    links.forEach(link => link.classList.remove('active'));
    innerElements.forEach(el => el.classList.remove('open'));
    if (!isAlreadyActive) {
      this.classList.add('active');
      targetElement.classList.add('open');
    }
    e.preventDefault();
  }

  function handleMouseUpTouchStartKeyUp(e) {
    const isInsideDropdown = Array.from(links).concat(Array.from(innerElements)).some(el => el.contains(e.target));
    if (!isInsideDropdown || e.keyCode === 27) {
      links.forEach(link => link.classList.remove('active'));
      innerElements.forEach(el => el.classList.remove('open'));
    }
  }

  links.forEach(link => link.addEventListener('click', handleClick));
  document.addEventListener('mouseup', handleMouseUpTouchStartKeyUp);
  document.addEventListener('touchstart', handleMouseUpTouchStartKeyUp);
  document.addEventListener('keyup', handleMouseUpTouchStartKeyUp);


  // sliders
  var sliderEvents = new Swiper('.js-slider-events', {
    slidesPerView: 1,
    spaceBetween: 28,
    loop: true,
    navigation: {
      prevEl: '.js-slider-events-prev',
      nextEl: '.js-slider-events-next'
    },
    pagination: {
      clickable: true,
      el: '.js-slider-events-pagination',
    },
    breakpoints: {
      700: {
        slidesPerView: 2
      }
    }
  });


}, false);
